<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="1">
        <v-btn color="warning" @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="11" class="text-h5 font-weight-regular">
        Lavorazione convoglio {{ convoglio.nome }}
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">   
           <ConvoyExecution :convoglio="convoglio" /> 
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ConvoyExecution from "../../components/misc/ConvoyExecution";

export default {
  name: "LavorazioneConvoglioArrivo", 
  components: {
    ConvoyExecution
  },
  mixins: [],
  props: ["convoglioId"],
  data() {
    return {
      convoglio: {},
      editPanel: [0, 1, 2, 3],
    };
  },
  created(){
    this.$eventBus.$on('convoyReload', async () => {
        await this.fetchData();
    });
  },
  async mounted() {
    try {
      this.fetchData();
    } catch (e) {
      console.log(e);
    }
  },
    methods: {
    async fetchData() {

        this.convoglio = await this.$api.get(this.$apiConfiguration.BASE_PATH + "convogli/getConvoglioWithCarriConvogliCancellati/" + this.convoglioId);
        await this.$api.trainData.saveCarroConvogliPosizioneOriginale(this.convoglio.id);
        this.convoglio.listCarri = this._.orderBy(this.convoglio.listCarri, [cc => cc.posizioneOriginale]); 

    },
   },
  };
</script>

<style scoped>
</style>